/**
* Covid Response Popup window
*/

const popup = () => {
  // window.addEventListener('load', () => {
  //   setTimeout(
  //     function open(event) {
  //       //@ts-ignore
  //       document.querySelector('.covid-response').style.display = "block";
  //       document.body.style.overflow = 'hidden';
  //       sessionStorage.setItem('hasVisited', `true`);
  //     },
  //     1000
  //   )
  // });
  function open() {
    //@ts-ignore
    document.querySelector('.covid-response').style.display = "block";
    document.body.style.overflow = 'hidden';
    sessionStorage.setItem('hasVisited', `true`);
  }
  open();
  document.querySelector(".covid-response__close")?.addEventListener("click", () => {
    //@ts-ignore
    document.querySelector(".covid-response").style.display = "none";
    document.body.style.overflow = 'auto';
  });
}

export default popup