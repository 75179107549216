/**
* Accordion function
*/

const accordionFunc = () => {
  const accordion = document.querySelector(".js-accordion");

  if (accordion) {
    let items = accordion.querySelectorAll<HTMLInputElement>(".js-accordion-item");

    for (let i = 0; i < items.length; i++) {
      items[i].addEventListener("click", () => {
        let collapseContent = items[i].querySelector<HTMLInputElement>(".js-accordion-collapse");
        let arrow = items[i].querySelector<HTMLInputElement>(".js-accordion-arrow")

        if (collapseContent) {
          collapseContent.classList.toggle("show");
          arrow?.classList.toggle("flip");
          // if (!collapseContent.classList.contains("show")) {
          //   console.log('clicked')
          //   let itemHeight = collapseContent.scrollHeight;
          //   // if(screen.width > 767) {
          //   //   collapseContent.style.height = (itemHeight + 50) + 'px';
          //   // }
          //   // else {
          //   //   collapseContent.style.height = (itemHeight +30) + 'px';
          //   // }
          // }
          // else {
          //   // collapseContent.classList.remove("show");
          //   // collapseContent.style.height = 0 + 'px';
          //   arrow?.classList.remove("flip");
          // }
        }
      })
    }
  }
}

export default accordionFunc;