import accordionFunc from './components/accordionFunc';
import forEachPolyfill from './components/forEachPolyfill';
import popup from './components/popup';
import flatpickrInit from './components/flatpickrInit';

let ss = sessionStorage;

document.addEventListener(
  'DOMContentLoaded',
  () => {
    forEachPolyfill()
    accordionFunc()
    if (!sessionStorage.hasOwnProperty('hasVisited')) {
      popup();
    }
    flatpickrInit();
  },
  false
)
